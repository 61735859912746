import { Page } from '../../components/Page/Page';
import React from 'react';
import { Button, ButtonProps, Stack } from '@mui/material';
import { MyTypography } from '../../components/Typography/Typography';
import { Illustration } from '../../components/Illustration/Illustration';
import { AddToCalendarButton } from '../../components/AddToCalendarButton/AddToCalendarButton';
import { useScreenSize } from '../../hooks/useScreenSize';

export const EliButton: React.FC<ButtonProps> = (props) => {
  return (
    <Button
      {...props}
      sx={{
        height: '2.2em',
        borderRadius: '1.1em',
        backgroundColor: 'black',
        fontFamily: 'mirage_medium',
        width: '240px',
        color: 'white',
        '&:hover': {
          backgroundColor: '#343434',
        },
      }}
    />
  );
};

export const DressCode: React.FC = () => {
  const { isMobile } = useScreenSize();
  return (
    <Page>
      <Stack mt={2} gap={2}>
        <Stack gap={2} justifyContent={'center'} alignItems={'center'}>
          <AddToCalendarButton
            calendarEvent={{
              address: 'Basílica Menor de Nuestra Señora de Guadalupe (El Dique), Xalapa, Ver.',
              durationInMinutes: 8 * 60,
              title: 'Boda Eli y Dani',
              description: 'Acompañanos en nuestra boda',
              startDate: new Date(2024, 10, 9, 16, 0),
              endDate: new Date(2024, 10, 9, 22, 0),
            }}
          />
          <EliButton component={'a'} href={'/#assitance'}>
            Confirmar asistencia
          </EliButton>
        </Stack>
      </Stack>
      <Stack mt={isMobile ? 8 : 3} gap={2}>
        <MyTypography
          {...(isMobile ? { fontSize: '2.2em' } : {})}
          color={'nude'}
          font={'MIRAGE_MEDIUM'}
          variant={'h2'}
          align={'center'}
        >
          CÓDIGO DE VESTIMENTA
        </MyTypography>
        <MyTypography
          {...(isMobile ? { fontSize: '1.6em', mt: 6 } : {})}
          font={'MIRAGE'}
          align={'center'}
          variant={'h4'}
        >
          FORMAL DE NOCHE
        </MyTypography>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <Illustration
            illustration={'dressCode'}
            alt={'Código de vestimenta'}
            styles={{
              width: '400px',
              height: '400px',
              ...(isMobile && { width: 'auto', height: '250px' }),
            }}
          />
        </Stack>
        <Stack>
          <MyTypography variant={'h6'} font={'MIRAGE'} align={'center'}>
            Mujeres: Vestido largo (no blanco/beige).
          </MyTypography>
          <MyTypography variant={'h6'} font={'MIRAGE'} align={'center'}>
            Hombres: Traje.
          </MyTypography>
        </Stack>
      </Stack>
    </Page>
  );
};

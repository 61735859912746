import React from 'react';
import { Stack } from '@mui/material';
import { MyTypography } from '../../components/Typography/Typography';
import { Page } from '../../components/Page/Page';
import { Illustration } from '../../components/Illustration/Illustration';
import { Image } from '../../components/Image/Image';
import { useScreenSize } from '../../hooks/useScreenSize';

export const Map: React.FC = () => {
  const { isMobile } = useScreenSize();
  return (
    <Page>
      <Stack p={2} mt={2}>
        <MyTypography align={'center'} font={'MIRAGE'} variant="h6">
          ¡Agradecemos mucho tu puntualidad para la ceremonia!
        </MyTypography>
        <Illustration alt={'div'} illustration={'rule'} styles={{ height: '10px' }} />
        <MyTypography align={'center'} font={'MIRAGE_MEDIUM'} variant="h4" mt={4}>
          ¿Cómo llegar a Quinta San Martín?
        </MyTypography>
        <Stack justifyContent={'center'} alignItems={'center'}>
          <Image image={'map'} style={{ height: isMobile ? '300px' : '400px', width: 'auto' }} />
        </Stack>
        <MyTypography align={'center'} font={'MIRAGE'} variant="h5" mt={4}>
          Saliendo de Xalapa, sigue la carretera hacia Naolinco, <br />
          posteriormente toma la desviación hacia La Concepción.
        </MyTypography>
        <MyTypography align={'center'} font={'MIRAGE'} variant="h6" mt={4}>
          Tiempo aprox. en auto: 40 min.
        </MyTypography>
      </Stack>
    </Page>
  );
};

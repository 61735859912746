import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// Define el tipo para el objeto que será retornado por el hook
type ScreenSize = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  isDesktop: boolean;
  isMobile: boolean;
};

export function useScreenSize(): ScreenSize {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const sm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const md = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const lg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
  const xl = useMediaQuery(theme.breakpoints.only('xl'));

  const isDesktop = md || lg || xl;
  const isMobile = xs || sm;

  return { xs, sm, md, lg, xl, isDesktop, isMobile };
}

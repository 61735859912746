import React from 'react';
import { Stack } from '@mui/material';
import { Page } from '../../components/Page/Page';
import { MyTypography } from '../../components/Typography/Typography';
import { Illustration } from '../../components/Illustration/Illustration';
import { useScreenSize } from '../../hooks/useScreenSize';

const content = (
  <>
    <Stack my={4} gap={4} sx={{ textAlign: 'center' }}>
      <MyTypography font={'RESTORA_ITALIC'} variant={'h5'}>
        Los momentos más hermosos merecen ser compartidos. ¡Gracias!
      </MyTypography>
      <MyTypography font={'RESTORA_ITALIC'} variant={'h5'}>
        Agradecemos puedas <br />
        incluirnos en tus oraciones.
      </MyTypography>
      <MyTypography font={'RESTORA_ITALIC'} variant={'h5'}>
        Eli y Dani.
      </MyTypography>
    </Stack>
    <Illustration
      alt={'Eli y Dani'}
      illustration={'eli_dani_label'}
      styles={{ width: '100%', height: 'auto' }}
    />
  </>
);

export const Prayer: React.FC = () => {
  const { isMobile } = useScreenSize();
  if (isMobile) {
    return (
      <Stack
        className={'page'}
        sx={{
          minHeight: '30vh',
        }}
      >
        {content}
      </Stack>
    );
  }
  return <Page justifyContent={'space-between'}>{content}</Page>;
};

import { Page } from '../../components/Page/Page';
import React from 'react';
import { Stack } from '@mui/material';
import { Logo } from '../../components/Logo/Logo';
import { useScreenSize } from '../../hooks/useScreenSize';
//import { getIllustration } from '../../components/Illustration/Illustration';
import { MyTypography } from '../../components/Typography/Typography';
import { DateComponent } from '../Summary/Summary';

const HeroMobile = () => {
  return (
    <Page
      sx={{
        backgroundColor: 'black',
        // backgroundImage: `url(${getIllustration('hero_slide_mobile')})`,
        // backgroundPosition: 'center center',
        // backgroundSize: '70%',
        // backgroundRepeat: 'no-repeat',
      }}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <Stack sx={{ width: '100%' }}>
        <Stack ml={3} justifyContent={'flex-start'}>
          <Stack justifyContent={'center'} alignItems={'center'} sx={{ width: '180px' }}>
            <Logo white styles={{ height: '160px' }} />
            <MyTypography
              className={'fade-in'}
              font={'MIRAGE'}
              color={'white'}
              fontSize={'1.7em'}
              lineHeight={1}
            >
              Daniel&
              <br />
              Elideth
            </MyTypography>
          </Stack>
        </Stack>
        <Stack sx={{ width: '100%' }} alignItems={'end'}>
          <Stack mt={5} mr={3} sx={{ width: '180px' }}>
            <Stack>
              <DateComponent className={'fade-in'} fontSize={'3.5em'} color={'white'}>
                09
              </DateComponent>
              <DateComponent className={'fade-in'} fontSize={'3.5em'} color={'white'}>
                11
              </DateComponent>
              <DateComponent className={'fade-in'} fontSize={'3.5em'} color={'white'}>
                24
              </DateComponent>
            </Stack>
            <Stack>
              <MyTypography
                className={'fade-in'}
                color={'white'}
                font={'MIRAGE'}
                align={'center'}
                fontSize={'2em'}
                lineHeight={1}
              >
                XALAPA,
                <br />
                VER
              </MyTypography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Page>
  );
};

export const Hero: React.FC = () => {
  const { isMobile } = useScreenSize();
  if (isMobile) {
    return <HeroMobile />;
  }
  return (
    <Page justifyContent={'center'} alignItems={'center'}>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          height: '90%',
        }}
      >
        <Logo />
      </Stack>
    </Page>
  );
};

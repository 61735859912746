import React from 'react';
import ReactDOM from 'react-dom/client';
import './main.css';
import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
//
// import "./assets/fonts/made_mirage/MADE Mirage Black PERSONAL USE.otf";
// import "./assets/fonts/made_mirage/MADE Mirage Bold PERSONAL USE.otf";
// import "./assets/fonts/made_mirage/MADE Mirage Medium PERSONAL USE.otf";
// import "./assets/fonts/made_mirage/MADE Mirage Regular PERSONAL USE.otf";
// import "./assets/fonts/made_mirage/MADE Mirage Thin PERSONAL USE.otf";
//
// import "./assets/fonts/nasir-udin-restora/Nasir Udin - RestoraExtraLight.otf";
// import "./assets/fonts/nasir-udin-restora/Nasir Udin - RestoraThinItalic.otf";
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

import React from 'react';
import mapa from '../../assets/images/mapa.jpg';
import iglesia from '../../assets/images/iglesia.jpg';
import salon from '../../assets/images/salon.jpg';
import hotel from '../../assets/images/hotel.jpg';
import eliYDaniArco from '../../assets/images/eli_y_dani_arco.jpg';
import eliYDaniArcoMovil from '../../assets/images/eli_dani_arco_movil.png';
import eliYDaniAbrazo from '../../assets/images/eli_y_dani_abrazo.jpg';

interface Image {
  src: string;
  alt: string;
}

const MappedImages = {
  church: {
    src: iglesia,
    alt: 'Iglésia',
  },
  reception: {
    src: salon,
    alt: 'Recepción',
  },
  hotel: {
    src: hotel,
    alt: 'Hotel',
  },
  eliDani1: {
    src: eliYDaniArco,
    alt: 'EliDani1',
  },
  eliDaniArcoMobile: {
    src: eliYDaniArcoMovil,
    alt: 'EliDani',
  },
  eliDaniAbrazo: {
    src: eliYDaniAbrazo,
    alt: 'EliDani1',
  },
  map: {
    src: mapa,
    alt: 'Mapa',
  },
} as const;

export type AvailableImages = keyof typeof MappedImages;

interface ImageProps {
  image: AvailableImages;
  style?: React.CSSProperties;
}

export const Image = (props: ImageProps) => {
  const imageSrc = MappedImages[props.image].src;
  const imageAlt = MappedImages[props.image].alt;
  return <img style={props.style} src={imageSrc} alt={imageAlt} />;
};

export function getImage(image: AvailableImages): Image {
  return {
    src: MappedImages[image].src,
    alt: MappedImages[image].alt,
  };
}

import React from 'react';
import logo from '../../assets/eyd_logo.svg';
import amazon from '../../assets/illustrations/amazon.png';
import card from '../../assets/illustrations/card.png';
import sobre from '../../assets/illustrations/sobre.png';
import liverpool from '../../assets/illustrations/liverpool.png';
import hr from '../../assets/illustrations/hr.png';
import monio from '../../assets/illustrations/moño.png';
import ramo from '../../assets/illustrations/ramo.png';
import whatsapp from '../../assets/illustrations/whatsapp.png';
import dressCode from '../../assets/illustrations/dresscode.png';
import eli_dani_label from '../../assets/illustrations/eli_y_dani_logo_negro.png';
import hero_slide_mobile from '../../assets/illustrations/hero_slide_mobile.png';

const illustrations = {
  placeholder: logo,
  rule: hr,
  liverpool: liverpool,
  amazon: amazon,
  card: card,
  sobre: sobre,
  monio: monio,
  ramo: ramo,
  whatsapp: whatsapp,
  dressCode: dressCode,
  eli_dani_label: eli_dani_label,
  hero_slide_mobile: hero_slide_mobile,
} as const;

type IllustationsAvailable = keyof typeof illustrations;

interface IllustrationProps {
  styles?: React.CSSProperties;
  alt: string;
  illustration: IllustationsAvailable;
  onClick?: () => void;
}

export function getIllustration(illustration: IllustationsAvailable) {
  return illustrations[illustration];
}

export const Illustration: React.FC<IllustrationProps> = ({
  styles,
  alt,
  illustration,
  onClick,
}) => {
  const logo = illustrations[illustration] || illustrations.placeholder;
  const clickable = Boolean(onClick);
  return (
    <img
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        height: '50px',
        objectFit: 'contain',
        cursor: clickable ? 'pointer' : 'auto',
        ...styles,
      }}
      src={logo}
      alt={alt}
      onClick={onClick}
    />
  );
};

import React from 'react';
import { Typography, TypographyProps } from '@mui/material';

const customFonts = {
  MIRAGE: 'mirage',
  MIRAGE_BLACK: 'mirage_black',
  MIRAGE_BOLD: 'mirage_bold',
  MIRAGE_MEDIUM: 'mirage_medium',
  MIRAGE_THIN: 'mirage_thin',
  RESTORA_LIGHT: 'restora_light',
  RESTORA_ITALIC: 'restora_italic',
} as const;

const customColors = {
  nude: '#ddc6b0',
  hide: 'transparent',
  white: '#ffffff',
} as const;

type AvailableFontsMapped = typeof customFonts;
type AvailableFonts = keyof AvailableFontsMapped;
type colors = keyof typeof customColors;

export interface MyTypographyProps extends TypographyProps {
  font?: AvailableFonts;
  color?: colors;
  hide?: boolean;
}

export const MyTypography: React.FC<MyTypographyProps> = ({ sx, font, color, hide, ...props }) => {
  const fontName = font ? customFonts[font] : 'roboto';
  let colorName = color ? customColors[color] : 'black';
  if (hide) colorName = customColors.hide;
  return <Typography sx={{ ...sx, fontFamily: fontName, color: colorName }} {...props} />;
};

import { Stack } from '@mui/material';
import React from 'react';
import { useScreenSize } from '../../hooks/useScreenSize';

type StackProps = React.ComponentProps<typeof Stack>;

export const Page: React.FC<StackProps> = ({ children, sx, ...props }) => {
  const { isMobile } = useScreenSize();
  const customSx = {
    ...sx,
    minWidth: '100%',
    minHeight: '100vh',
    ...(isMobile && {
      mb: 8,
    }),
  };
  return (
    <Stack className={'page'} sx={customSx} {...props}>
      {children}
    </Stack>
  );
};

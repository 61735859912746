import React from 'react';
import { Grid, Stack } from '@mui/material';
import { Page } from '../../components/Page/Page';
import { MyTypography } from '../../components/Typography/Typography';
import { Illustration } from '../../components/Illustration/Illustration';
import { useScreenSize } from '../../hooks/useScreenSize';
import { openLinkInNewTab } from '../Hotel/Hotel';

export const GiftTable: React.FC = () => {
  const { isDesktop, isMobile } = useScreenSize();
  return (
    <>
      <Page justifyContent={'center'} alignItems={'center'}>
        <Stack justifyContent={'center'} alignItems={'center'} gap={2} width={'100%'}>
          <MyTypography
            {...(isMobile ? { fontSize: '1.8em' } : {})}
            variant="h2"
            color={'nude'}
            font={'MIRAGE'}
            align={'center'}
          >
            MESA DE REGALOS
          </MyTypography>
          <Stack
            sx={{
              backgroundColor: isMobile ? 'black' : 'inherit',
              width: '100%',
            }}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <MyTypography
              maxWidth={isMobile ? '280px' : undefined}
              color={isMobile ? 'white' : undefined}
              font={'MIRAGE'}
              align={'center'}
              p={2}
            >
              Para nosotros el mejor regalo es compartir este día con ustedes.{!isMobile && <br />}{' '}
              En caso de que desees darnos algún obsequio, ponemos a su disposición las siguientes
              opciones:
            </MyTypography>
          </Stack>
        </Stack>
        <Grid container mt={4} gap={2}>
          {isDesktop && (
            <>
              <Grid item md={2} />
            </>
          )}
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              textAlign: 'center',
              mb: isMobile ? 2 : 0,
            }}
          >
            <Illustration
              alt={'Liverpool'}
              onClick={() =>
                openLinkInNewTab('https://mesaderegalos.liverpool.com.mx/milistaderegalos/51446799')
              }
              illustration={'liverpool'}
              styles={{ height: isMobile ? '60px' : '100px', cursor: 'pointer' }}
            />
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
            <Illustration
              alt={'Amazon'}
              illustration={'amazon'}
              onClick={() =>
                openLinkInNewTab('https://www.amazon.com.mx/wedding/registry/559FM0TCUM1M')
              }
              styles={{ height: isMobile ? '50px' : '80px', cursor: 'pointer' }}
            />
          </Grid>
          {isDesktop && (
            <>
              <Grid item md={2} />
            </>
          )}
          <Grid item xs={12} mt={4} sx={{ textAlign: 'center' }} mb={4}>
            <Stack sx={{ maxWidth: '600px', margin: '0 auto' }}>
              <MyTypography font={'MIRAGE'} align={'center'}>
                Si realizas compras personales en Liverpool y das nuestro número de evento es una
                forma adicional de apoyarnos en empezar nuestro nuevo hogar.
              </MyTypography>
            </Stack>
          </Grid>
          {isDesktop && (
            <>
              <Grid item md={2} />
            </>
          )}
          <Grid
            xs={12}
            md={4}
            justifyContent={'center'}
            sx={{
              display: 'flex',
              mb: isMobile ? 4 : 0,
            }}
          >
            <Stack direction={'row'} gap={2}>
              <Illustration
                alt={'Tarjeta de regalo'}
                illustration={'card'}
                styles={{ height: isMobile ? '80px' : '100px' }}
              />
              <Stack>
                <MyTypography font={'MIRAGE_MEDIUM'} align={'left'}>
                  Transferencia
                </MyTypography>
                <MyTypography font={'RESTORA_ITALIC'} align={'left'}>
                  Clabe BBVA <br />
                  012 840 01598599218 8 <br /> Luis Daniel Acosta Díaz
                </MyTypography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
            <Stack>
              <Illustration
                alt={'Lluvia de sobres'}
                illustration={'sobre'}
                styles={{ height: isMobile ? '40px' : '60px' }}
              />
              <MyTypography font={'MIRAGE_MEDIUM'}>Efectivo el día del evento</MyTypography>
              <MyTypography font={'MIRAGE'}>En el lugar contaremos con sobres.</MyTypography>
            </Stack>
          </Grid>
          {isDesktop && (
            <>
              <Grid item md={2} />
            </>
          )}
        </Grid>
      </Page>
    </>
  );
};

import React from 'react';
import { Page } from '../../components/Page/Page';
import { getImage } from '../../components/Image/Image';
import { useScreenSize } from '../../hooks/useScreenSize';
import { Stack } from '@mui/material';

export const Hug: React.FC = () => {
  const { isMobile } = useScreenSize();
  if (isMobile) {
    return (
      <Stack
        className={'page'}
        sx={{
          backgroundImage: `url(${getImage('eliDaniAbrazo').src})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          minHeight: '30vh',
        }}
      ></Stack>
    );
  }
  return (
    <Page
      sx={{
        backgroundImage: `url(${getImage('eliDaniAbrazo').src})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
      }}
    ></Page>
  );
};

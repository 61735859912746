import { Grid, Stack } from '@mui/material';
import React from 'react';
import { Page } from '../../components/Page/Page';
import { MyTypography, MyTypographyProps } from '../../components/Typography/Typography';
import { Illustration } from '../../components/Illustration/Illustration';
import { useScreenSize } from '../../hooks/useScreenSize';

const ParentItem: React.FC<MyTypographyProps> = ({ children, ...rest }) => {
  const { isMobile } = useScreenSize();
  return (
    <MyTypography
      component={'h2'}
      align={'center'}
      font={'RESTORA_ITALIC'}
      fontSize={isMobile ? '1.1em' : '1.2em'}
      {...rest}
    >
      {children}
    </MyTypography>
  );
};

export const MyParents: React.FC = () => {
  const { isMobile } = useScreenSize();
  return (
    <Page justifyContent={'center'} alignItems={'center'}>
      <Grid container p={4}>
        <Grid item xs={12}>
          <MyTypography
            component={'h1'}
            align={'center'}
            font={'MIRAGE_MEDIUM'}
            fontSize={isMobile ? '1.8em' : '3em'}
          >
            NUESTROS PADRES
          </MyTypography>
        </Grid>
        <Grid item xs={12} md={6} mt={isMobile ? 5 : 2}>
          <Stack>
            <Illustration
              illustration={'monio'}
              alt={'Padres del novio'}
              styles={{ height: isMobile ? '50px' : '80px' }}
            />
            <ParentItem>José Luis Acosta García</ParentItem>
            <ParentItem>Reina Díaz Meza ✞</ParentItem>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} mt={isMobile ? 5 : 2}>
          <Stack>
            <Illustration
              illustration={'ramo'}
              alt={'Padres de la novia'}
              styles={{ height: isMobile ? '50px' : '80px' }}
            />
            <ParentItem>Roberto Vásquez Pino</ParentItem>
            <ParentItem>Elizabeth Silvia Hernández Mendoza</ParentItem>
          </Stack>
        </Grid>
        <Grid item xs={12} mt={10}>
          <Stack>
            <MyTypography
              component={'h1'}
              align={'center'}
              font={'MIRAGE_MEDIUM'}
              fontSize={isMobile ? '1.8em' : '3em'}
            >
              Y {isMobile && <br />} PADRINOS
            </MyTypography>
            <ParentItem>Jorge Pérez Flores</ParentItem>
            <ParentItem>Margarita Cortés Delgado</ParentItem>
          </Stack>
        </Grid>
      </Grid>
    </Page>
  );
};

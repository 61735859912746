import React from 'react';
import { Page } from '../../components/Page/Page';
import { Image } from '../../components/Image/Image';
import { MyTypography } from '../../components/Typography/Typography';
import { Grid, Stack } from '@mui/material';
import { EliButton } from '../DressCode/DressCode';
import { useScreenSize } from '../../hooks/useScreenSize';
import { handleWhatsAppClick } from '../ConfirmAssistance/ConfirmAssistance';

export const openLinkInNewTab = (url: string) => {
  window.open(url, '_blank');
};

export const Hotel: React.FC = () => {
  const { isMobile } = useScreenSize();
  return (
    <>
      <Page py={2} justifyContent={'center'} alignItems={'center'}>
        <Stack gap={1}>
          <MyTypography
            {...(isMobile ? { fontSize: '1.8em', mt: 2 } : {})}
            align={'center'}
            variant={'h2'}
            color={'nude'}
            font={'MIRAGE_MEDIUM'}
          >
            SUGERENCIA <br />
            DE HOSPEDAJE
          </MyTypography>
          <MyTypography
            {...(isMobile ? { fontSize: '1.6em', mt: 2 } : {})}
            align={'center'}
            font={'MIRAGE'}
            variant={'h3'}
          >
            HOTEL KLIMT
          </MyTypography>
        </Stack>
        <Grid container mt={3}>
          <Grid item xs={12} md={6} textAlign={'center'} alignContent={'center'}>
            <Image
              image={'hotel'}
              style={{
                height: '400px',
                width: 'auto',
                ...(isMobile ? { width: '80%', height: 'auto' } : {}),
              }}
            />
          </Grid>
          <Grid mt={6} p={isMobile ? 4 : 0} item xs={12} md={6} alignContent={'center'}>
            <Stack
              sx={isMobile ? {} : { maxWidth: '400px', margin: '0 auto' }}
              gap={2}
              justifyContent={'center'}
              alignItems={'center'}
              textAlign={'center'}
            >
              <MyTypography fontSize={'1.2em'} font={'MIRAGE_MEDIUM'}>
                Código de reserva: <br />
                &quot;Boda Eli y Luis Daniel&quot;
              </MyTypography>
              <MyTypography font={'MIRAGE'} fontSize={'1.2em'}>
                Av Miguel Alemán #310, Laderas del Macuiltepetl, 91133 Xalapa-Enríquez, Ver.
              </MyTypography>
              <EliButton onClick={() => openLinkInNewTab('https://hotelklimtxalapa.com/')}>
                SITIO WEB
              </EliButton>
              <MyTypography mt={1} font={'MIRAGE'} fontSize={'1.2em'}>
                Habitaciones sencillas, dobles, triples y cuádruples disponibles con opción de
                desayuno incluido.
              </MyTypography>
              <EliButton onClick={() => handleWhatsAppClick('522281462519')}>
                Whatsapp ventas
              </EliButton>
              <MyTypography font={'MIRAGE'} fontSize={'1.2em'}>
                228146 2519 - Lic. Nancy Guevara
              </MyTypography>
            </Stack>
          </Grid>
        </Grid>
      </Page>
    </>
  );
};

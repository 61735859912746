import React from 'react';
import { Stack } from '@mui/material';
import { Hero } from './Pages/Hero/Hero';
import { Summary } from './Pages/Summary/Summary';
import { MyParents } from './Pages/MyParents/MyParents';
import { Details } from './Pages/Details/Details';
import { Map } from './Pages/Map/Map';
import { DressCode } from './Pages/DressCode/DressCode';
import { Hotel } from './Pages/Hotel/Hotel';
import { GiftTable } from './Pages/GiftTable/GiftTable';
import { ConfirmAssistance } from './Pages/ConfirmAssistance/ConfirmAssistance';
import { Hug } from './Pages/Hug/Hug';
import { Prayer } from './Pages/Prayer/Prayer';

function App() {
  return (
    <Stack sx={{ minHeight: '100%' }}>
      <Hero />
      <Summary />
      <MyParents />
      <Details />
      <Map />
      <DressCode />
      <Hotel />
      <GiftTable />
      <ConfirmAssistance />
      <Hug />
      <Prayer />
    </Stack>
  );
}

export default App;

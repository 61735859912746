import React from 'react';
import { Page } from '../../components/Page/Page';
import { Box, Grid, Stack } from '@mui/material';
import { MyTypography } from '../../components/Typography/Typography';
import { AvailableImages, Image } from '../../components/Image/Image';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useScreenSize } from '../../hooks/useScreenSize';
import { openLinkInNewTab } from '../Hotel/Hotel';

interface LocationDetailsProps {
  location: string;
  time: string;
  title: string;
  address: string;
  image: AvailableImages;
  mapUrl: string;
}

const LocationDetails: React.FC<LocationDetailsProps> = ({
  location,
  time,
  address,
  title,
  image,
  mapUrl,
}) => {
  const { isMobile } = useScreenSize();
  return (
    <Stack>
      <MyTypography
        {...(isMobile ? { fontSize: '1.8em' } : {})}
        variant="h3"
        font={'MIRAGE'}
        align={'center'}
      >
        {title}
      </MyTypography>
      <MyTypography
        {...(isMobile ? { fontSize: '1.7em' } : {})}
        variant="h3"
        font={'MIRAGE_MEDIUM'}
        align={'center'}
      >
        {time}
      </MyTypography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          my: 2,
          ...(isMobile &&
            {
              //boxSizing: 'content-box',
              //mx: '2em',
            }),
        }}
      >
        <Image
          image={image}
          style={{
            display: 'flex',
            height: '350px',
            width: 'auto',
            ...(isMobile ? { width: '80%', height: 'auto' } : {}),
          }}
        />
      </Box>
      <Stack sx={{ ...(isMobile ? { width: '80%', margin: '0 auto' } : {}) }}>
        <MyTypography variant="h6" font={'MIRAGE_MEDIUM'} align={'center'}>
          {location}
        </MyTypography>
        <MyTypography font={'MIRAGE'} align={'center'}>
          {address}
        </MyTypography>
        <MyTypography align={'center'} mt={4}>
          <LocationOnIcon
            onClick={() => openLinkInNewTab(mapUrl)}
            sx={{ fontSize: 50, cursor: 'pointer' }}
          />
        </MyTypography>
      </Stack>
    </Stack>
  );
};

export const Details: React.FC = () => {
  const { isMobile } = useScreenSize();
  return (
    <Page {...(isMobile ? { py: 5, mb: 2 } : {})}>
      <Grid p={2} container mt={2} justifyContent={'center'}>
        <Grid item xs={12}>
          <MyTypography
            {...(isMobile ? { fontSize: '2.4em' } : {})}
            align={'center'}
            font={'MIRAGE_MEDIUM'}
            variant="h2"
            color={'nude'}
          >
            DETALLES
          </MyTypography>
        </Grid>
        <Grid item md={6} mt={2} mb={isMobile ? 8 : undefined}>
          <LocationDetails
            location={'Basílica Menor de Nuestra Señora de Guadalupe (El Dique)'}
            time={'4:00 PM'}
            title={'CEREMONIA'}
            address={'Mártires De Xalapa S/N Col. Felipe Carrillo Puerto. Xalapa, Ver.'}
            image={'church'}
            mapUrl={'https://maps.app.goo.gl/R29d6Dioh7xbvgyu5'}
          />
        </Grid>
        <Grid item md={6} mt={2}>
          <LocationDetails
            title={'RECEPCIÓN'}
            time={'6:00 PM'}
            image={'reception'}
            location={'Quinta San Martín'}
            address={'Av. Higueras #2 Col. San Martín. Municipio de Jilotepec, Veracruz.'}
            mapUrl={'https://maps.app.goo.gl/2Yq1ak4waG9RRVs97'}
          />
        </Grid>
      </Grid>
    </Page>
  );
};

import React from 'react';
import { generateGoogleCalendarUrl, generateIcsCalendarFile } from './AddToCalendarButtonTooltip';
import { CalendarEvent } from './CalendarEvent';
import { EliButton } from '../../Pages/DressCode/DressCode';
import { List, ListItemButton, ListItemText, Popover } from '@mui/material';

interface AddToCalendarButtonProps {
  calendarEvent: CalendarEvent;
}

export function AddToCalendarButton({ calendarEvent }: AddToCalendarButtonProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'addToCalendar-popover' : undefined;

  return (
    <>
      <EliButton aria-describedby={id} variant="contained" onClick={handleClick}>
        Agregar a calendario
      </EliButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        sx={{ width: '100%' }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List>
          <ListItemButton
            component="a"
            href={generateGoogleCalendarUrl(calendarEvent)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Google Calendar" />
          </ListItemButton>
          <ListItemButton
            component="a"
            href={generateIcsCalendarFile(calendarEvent)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Apple Calendar" />
          </ListItemButton>
          <ListItemButton
            component="a"
            href={generateIcsCalendarFile(calendarEvent)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <ListItemText primary="Outlook Calendar" />
          </ListItemButton>
        </List>
      </Popover>
    </>
  );
}

import React from 'react';
import { Page } from '../../components/Page/Page';
import { Grid, Stack, Box } from '@mui/material';
import { MyTypography, MyTypographyProps } from '../../components/Typography/Typography';
import { getImage } from '../../components/Image/Image';
import { useScreenSize } from '../../hooks/useScreenSize';

export const DateComponent: React.FC<MyTypographyProps> = ({ children, color, ...rest }) => {
  return (
    <MyTypography
      color={color}
      component={'span'}
      font={'MIRAGE'}
      align={'center'}
      fontSize={'6em'}
      {...rest}
    >
      {children}
    </MyTypography>
  );
};

const SummaryMobile: React.FC = () => {
  return (
    <Page
      sx={{
        backgroundImage: `url(${getImage('eliDaniArcoMobile').src})`,
        backgroundSize: '80%',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
      }}
    />
  );
};

export const Summary: React.FC = () => {
  const { isMobile } = useScreenSize();
  if (isMobile) return <SummaryMobile />;
  return (
    <Page justifyContent={'center'} alignItems={'center'}>
      <Stack
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh',
          width: '90%',
          backgroundImage: `url(${getImage('eliDani1').src})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      >
        <Grid container sx={{ width: '100%' }} justifyContent={'center'} alignItems={'center'}>
          <Grid item xs={3} justifyContent={'center'} alignItems={'center'}>
            <Stack>
              <Stack justifyContent={'center'} alignItems={'center'}>
                <MyTypography hide font={'MIRAGE'} fontSize={'2em'} lineHeight={1}>
                  Daniel&
                  <br />
                  Eli
                </MyTypography>
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={6} justifyContent={'center'} alignItems={'center'}></Grid>
          <Grid item xs={3} justifyContent={'center'} alignItems={'center'}>
            <Stack>
              <DateComponent hide>09</DateComponent>
              <DateComponent hide>11</DateComponent>
              <DateComponent hide>24</DateComponent>
            </Stack>
            <Stack>
              <MyTypography hide font={'MIRAGE'} align={'center'} fontSize={'2em'} lineHeight={1}>
                XALAPA,
                <br />
                VER
              </MyTypography>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <Stack justifyContent={'center'} alignItems={'center'} mt={4}>
        <Box
          sx={{
            maxWidth: '80%',
          }}
        >
          <MyTypography font={'MIRAGE'} align={'center'} fontSize={'1.5em'} lineHeight={1}>
            “Y AHORA PERMANECEN LA FE, LA ESPERANZA Y EL AMOR, ESTOS TRES; PERO EL MAYOR DE ELLOS ES
            EL AMOR” <br />
            <MyTypography font={'RESTORA_ITALIC'} component={'span'} fontSize={'1em'}>
              1 Corintios 13:13
            </MyTypography>
          </MyTypography>
        </Box>
      </Stack>
    </Page>
  );
};

import { Stack } from '@mui/material';
import React from 'react';
import { Page } from '../../components/Page/Page';
import { MyTypography } from '../../components/Typography/Typography';
import { Illustration } from '../../components/Illustration/Illustration';
import { useScreenSize } from '../../hooks/useScreenSize';

export const handleWhatsAppClick = (number: string) => {
  window.open(`https://wa.me/${number}`, '_blank');
};

export const ConfirmAssistance: React.FC = () => {
  const { isMobile } = useScreenSize();
  const handleNumberClick = () => handleWhatsAppClick('522285935668');

  return (
    <Page id={'assitance'} justifyContent={'center'} alignItems={'center'}>
      <Stack p={2} sx={{ textAlign: 'center', maxWidth: '600px' }} gap={isMobile ? 6 : 2}>
        <MyTypography
          {...(isMobile ? { fontSize: '1.8em' } : {})}
          variant={'h2'}
          color={'nude'}
          font={'MIRAGE_MEDIUM'}
        >
          CONFIRMACIÓN DE ASISTENCIA
        </MyTypography>
        <Stack>
          <MyTypography
            fontSize={isMobile ? '1em' : undefined}
            variant={'h5'}
            font={'RESTORA_ITALIC'}
          >
            Confirma tu asistencia a través de WhatsApp
          </MyTypography>
          <MyTypography
            sx={{ cursor: 'pointer' }}
            variant={'h4'}
            font={'MIRAGE_MEDIUM'}
            onClick={handleNumberClick}
          >
            228 593 5668
          </MyTypography>
        </Stack>
        <Illustration onClick={handleNumberClick} alt={'WhatsApp'} illustration={'whatsapp'} />
        <MyTypography font={'MIRAGE'}>
          Por favor, confirma al WhatsApp de Arlette Wedding Planner.
        </MyTypography>
        <MyTypography font={'MIRAGE'}>Si no podrás asistir, también háznoslo saber.</MyTypography>
        <MyTypography variant={'h4'} font={'MIRAGE_MEDIUM'}>
          - NO NIÑOS -
        </MyTypography>
      </Stack>
    </Page>
  );
};

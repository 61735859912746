import React from 'react';
import logo from '../../assets/eyd_logo.svg';
import logo_white from '../../assets/eyd_logo_white.svg';

// Definir la interfaz para las props
interface LogoProps {
  styles?: React.CSSProperties; // Propiedad opcional que representa los estilos en línea
  white?: boolean; // Propiedad opcional que indica si el logo es blanco
}

export const Logo: React.FC<LogoProps> = ({ styles, white = false }) => {
  return (
    <img
      style={{
        maxWidth: '100%',
        maxHeight: '100%',
        height: '250px',
        objectFit: 'contain',
        ...styles,
      }}
      src={white ? logo_white : logo}
      alt={'Eli y Dani :)'}
      className={'fade-in'}
    />
  );
};

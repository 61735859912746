import { CalendarEvent } from './CalendarEvent';
// utils

const MINUTE_IN_MS = 60 * 1000;

function getEndTime(calendarEvent: CalendarEvent) {
  return (
    calendarEvent.endDate ??
    addMinutesToDate(calendarEvent.startDate, calendarEvent.durationInMinutes)
  );
}

function formatDateForCalendarUrl(date: Date) {
  return date.toISOString().replace(/-|:|\.\d+/g, '');
}
function addMinutesToDate(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * MINUTE_IN_MS);
}

export function generateGoogleCalendarUrl(calendarEvent: CalendarEvent) {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

  const encodedUrl = encodeURI(
    [
      'https://www.google.com/calendar/render',
      '?action=TEMPLATE',
      `&text=${calendarEvent.title || ''}`,
      `&dates=${startDate || ''}`,
      `/${endDate || ''}`,
      `&details=${`${calendarEvent.description}\n` || ''}`,
      `&location=${calendarEvent.address || ''}`,
      '&sprop=&sprop=name:',
    ].join(''),
  );

  return encodedUrl;
}

// Generates ICS for Apple and Outlook calendars
export function generateIcsCalendarFile(calendarEvent: CalendarEvent) {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

  const encodedUrl = encodeURI(
    `data:text/calendar;charset=utf8,${[
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      `URL:${document.URL}`, // TODO: insert video app url here
      `DTSTART:${startDate || ''}`,
      `DTEND:${endDate || ''}`,
      `SUMMARY:${calendarEvent.title || ''}`,
      `DESCRIPTION:${calendarEvent.description || ''}`,
      `LOCATION:${calendarEvent.address || ''}`,
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n')}`,
  );

  return encodedUrl;
}
